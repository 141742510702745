<div id="app">
    <app-loader [isFpLoader]="true"></app-loader>
</div>

<button *ngIf="key" type="button" class="close btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
</button>

<div *ngIf="key?.modal || router.url.includes('login')" class="login" [ngClass]="{'loginModal' : key?.modal}">
    <div class="wrapper">
        <div class="container-fluid">
            <div class=" row">
                <div class="col-md-12">
                    <div class="loginInner1">
                        <div class="log-logo m-b-20 text-center"><img src="assets/img/{{env.host.name}}/logo.png" class="logo-login">
                        </div>
                        <div class="featured-box-login featured-box-secundary default">
                            <h4 class="text-center">LOGIN <i class="fas fa-hand-point-down"></i></h4>
                            <!---->
                            <form autocomplete="off" [formGroup]="formGroup" (ngSubmit)="onLogin()">
                                <div class="form-group m-b-20">
                                    <input name="User Name" formControlName="username" [placeholder]="key?.modal ? 'User Name / Mobile Number' : 'User Name'"
                                        type="text" class="form-control" aria-required="true" aria-invalid="false"> <i
                                        class="fa fa-user"></i>
                                    <!----> <small *ngIf="isSubmit && f.username?.errors?.required" class="text-danger" >Required</small>
                                </div>
                                <div class="form-group m-b-20">
                                    <input name="Password" formControlName="password" placeholder="Password"
                                        type="password" class="form-control" aria-required="true" aria-invalid="false">
                                    <i class="fa fa-key"></i>
                                    <!----> <small *ngIf="isSubmit && f.password?.errors?.required" class="text-danger" >Required</small>
                                </div>

                                <div class="form-group text-center" *ngIf="env.host.signUpActive">
                                    <a class="fp" href="javascript:void(0)" (click)="openModal(forgotPasswordModal)">Forgot Password</a>
                                 </div>

                                <div class="form-group text-center" [ngClass]="(env.host.demoLogin || env.host.social.isShow) ? 'mb-1' : 'mb-0'">
                                    <button type="submit" class="btn btn-submit btn-login" [ngClass]="{'disabled' : isLoad}">
                                        Login
                                        <i *ngIf="!isLoad" class=" ml-2 fa fa-sign-in-alt"></i>
                                        <i *ngIf="isLoad" class=" ml-2 fa fa-spinner fa-spin"></i>
                                    </button>
                                </div>
                                <div class="form-group text-center" [ngClass]="env.host.social.isShow ? 'mb-1' : 'mb-0'" *ngIf="env.host.demoLogin">
                                    <button type="button" (click)="loginWIthDemoId()" class="btn btn-submit btn-login" [ngClass]="{'disabled' : isLoadDemo}">
                                        Login with Demo ID
                                        <i *ngIf="!isLoadDemo" class=" ml-2 fa fa-sign-in-alt"></i>
                                        <i *ngIf="isLoadDemo" class=" ml-2 fa fa-spinner fa-spin"></i>
                                    </button>
                                </div>
                                <div class="social_links" *ngIf="env.host.social.isShow">
                                  <a *ngIf="env.host.social.fbLink != ''" [href]="env.host.social.fbLink" target="_blank">
                                    <img class="img-fluid" src="https://cdn-icons-png.flaticon.com/512/2504/2504903.png">
                                  </a>
                                  <a *ngIf="env.host.social.instaLink != ''" [href]="env.host.social.instaLink" target="_blank">
                                    <img class="img-fluid" src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png">
                                  </a>
                                  <a *ngIf="env.host.social.telLink != ''" [href]="env.host.social.telLink" target="_blank">
                                    <img class="img-fluid" src="https://cdn-icons-png.flaticon.com/512/2504/2504941.png">
                                  </a>
                                </div>
                                <div class="form-group text-center mb-0" *ngIf="env.host.social.isShow">
                                    <p class="whatsapp_text" *ngIf="env.host.whatsapptext != ''">
                                      {{env.host.whatsapptext}}
                                    </p>
                                    <a *ngIf="env.host.social.wpLink != ''" [href]="env.host.social.wpLink" target="_blank" class="btn whatsappIcon">
                                        <img class="img-fluid" src="https://cdn-icons-png.flaticon.com/512/3992/3992601.png">
                                        Get ID on Whatsapp
                                    </a>
                                    <a *ngIf="env.host.social.wpLink == ''" href="javascript:void(0)" class="btn whatsappIcon">
                                    <img class="img-fluid" src="https://cdn-icons-png.flaticon.com/512/3992/3992601.png">
                                    Get ID on Whatsapp
                                    </a>
                                </div>
                                <small class="recaptchaTerms">This site is protected by reCAPTCHA and the Google
                                    <a href="javascript:void(0)">Privacy Policy</a> and
                                    <a href="javascript:void(0)">Terms of Service</a> apply.
                                </small>
                                <p style="line-height: 2;" class="mt-1 align-center" *ngIf="env.host.email != ''"><a href="mailto:{{env.host.email}}" class="mail-link">{{env.host.email}}</a></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="extra_content">
  <p>{{env.host.content}}</p>
</div> -->

<iframe *ngIf="key?.signUpModal" class="custom-ifrm embed-responsive-item" scrolling="yes" [src]="env.host.iframeDomainUrl + 'signup/' + env.domain + env.trafficTag | safeURL" allowfullscreen="true" ></iframe>

<!-- Forgot Password -->
<ng-template #forgotPasswordModal>
    <div class="modal-header">
      <!-- <h4 class="modal-title pull-left">Forgot Password</h4> -->
      <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body p-0">
      <iframe class="custom-ifrm embed-responsive-item" scrolling="yes" [src]="env.host.iframeDomainUrl + 'forgot-password/' +env.domain | safeURL" allowfullscreen="true" ></iframe>
    </div>
  </ng-template>
  <!-- /Forgot Password -->
